import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Box, Drawer } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import TelegramIcon from '@mui/icons-material/Telegram';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import TourList from './components/TourList';
import TourPage from './components/TourPage';
import Terms from './components/Terms';
import logo from './logo.png';

const App = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  // Установка белого фона
  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff'; // Устанавливаем белый фон для body
  }, []);

  // Проверка размера экрана
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize(); // Установка начального значения
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <Router>
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: '#ffffff',
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          top: 0,
          zIndex: 1101,
        }}
      >
        <Toolbar>
          {/* Логотип */}
          <Box sx={{ flexGrow: 1 }}>
            <Link to="/">
              <img src={logo} alt="Logo" style={{ height: '40px', cursor: 'pointer' }} />
            </Link>
          </Box>

          {/* Ссылки в десктопной версии */}
          {!isMobile && (
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {/* Telegram */}
              <IconButton
                component="a"
                href="https://t.me/exaynaBot"
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: 'black' }}
              >
                <TelegramIcon />
              </IconButton>

              {/* Соглашение */}
              <Link to="/terms" style={{ textDecoration: 'none', color: '#ff9800', fontSize: '16px' }}>
                Соглашение
              </Link>
            </Box>
          )}

          {/* Гамбургер-меню для мобильной версии */}
          {isMobile && (
            <IconButton
              edge="end"
              aria-label="menu"
              onClick={toggleDrawer}
              sx={{
                color: 'black',
                zIndex: 1301,
              }}
            >
              {drawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer (Гамбургер-меню) */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100px', // Уменьшаем ширину Drawer
            backgroundColor: '#f8f9fa',
            padding: 2,
            zIndex: 1005,
          },
        }}
      >
        {/* Содержимое Drawer */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 1,
            marginTop: 2,
          }}
          onClick={toggleDrawer} // Закрыть при клике на пункт меню
          onKeyDown={toggleDrawer}
        >
          {/* Крестик */}
          <IconButton
            onClick={toggleDrawer}
            sx={{
              color: 'black',
              zIndex: 1301,
              backgroundColor: '#ffffff',
              borderRadius: '50%',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            {drawerOpen ? <CloseIcon /> : <MenuIcon />}
          </IconButton>

          {/* Домой */}
          <IconButton
            component={Link}
            to="/"
            sx={{
              color: '#ff9800',
              backgroundColor: '#ffffff',
              borderRadius: '50%',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <HomeIcon />
          </IconButton>

          {/* Telegram */}
          <IconButton
            component="a"
            href="https://t.me/exaynaBot"
            target="_blank"
            rel="noopener noreferrer"
            sx={{
              color: 'black',
              backgroundColor: '#ffffff',
              borderRadius: '50%',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
            }}
          >
            <TelegramIcon />
          </IconButton>

          {/* Соглашение */}
          <IconButton
            onClick={() => {
              window.location.href = '/terms'; // Замените на нужный URL
            }}
            sx={{
              color: '#ff9800',
              backgroundColor: '#ffffff',
              borderRadius: '10%',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.2)',
              textTransform: 'none',
              fontSize: '18px',
            }}
          >
            Соглашение
          </IconButton>
        </Box>
      </Drawer>

      <Routes>
        <Route path="/" element={<TourList />} />
        <Route path="/tours/:id" element={<TourPage />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
    </Router>
  );
};

export default App;
