import React, { useState } from 'react';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Gallery.css';

const TourGallery = ({ images }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0); // Для мобильного слайдера

  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: (index) => setCurrentIndex(index),
    arrows: false,
  };

  const openLightbox = (index) => {
    setPhotoIndex(index);
    setIsOpen(true);
  };

  if (!images || images.length === 0) {
    return <p>Нет доступных изображений</p>;
  }

  return (
    <>
      {/* Галерея для десктопа */}
      <div className="gallery-container desktop-gallery">
        <div className="gallery-main">
          <img
            src={images[0]}
            alt="Main"
            onClick={() => openLightbox(0)}
          />
        </div>
        <div className="gallery-thumbnails">
          {images.slice(1, 5).map((image, index) => (
            <div
              key={index + 1}
              className="thumbnail"
              onClick={() => openLightbox(index + 1)}
            >
              <img src={image} alt={`Thumbnail ${index + 1}`} />
            </div>
          ))}
        </div>
      </div>

      {/* Галерея для мобильных устройств */}
      <div className="mobile-gallery">
        <Slider {...sliderSettings}>
          {images.map((image, index) => (
            <div key={index} className="slider-image">
              <img src={image} alt={`Фото ${index + 1}`} />
            </div>
          ))}
        </Slider>
        <div className="image-indicator">
          {currentIndex + 1} из {images.length}
        </div>
      </div>

      {/* Lightbox для полноэкранного просмотра */}
      {isOpen && images.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
        />
      )}
    </>
  );
};

export default TourGallery;
