import React, { useState, useEffect } from 'react';
import './TermsOfService.css'; // Импортируем CSS файл

const TermsOfService = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
          setShowButton(window.scrollY > 300);
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);
    return (
        <div className="terms-container">
            

<>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      msoOutlineLevel: 1,
      background: "white"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "24.0pt",
          fontFamily: '"Times New Roman",serif',
          msoFareastFontFamily: '"Times New Roman"',
          color: "black",
          msoFontKerning: "18.0pt",
          msoFareastLanguage: "RU"
        }}
      >
        Пользовательское соглашение&nbsp;EXAYNA
        
      </span>
    </b>
  </p>
  <p
    className="MsoNormal"
    align="right"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      textAlign: "right",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      <br />
      <i>Редакция от 20.05.2024 года</i>
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      <br />
      <b>Кто мы такие</b>
      <br />
      <br />
      Мы – это ИП Гарин Алексей Анатольевич (ИНН: 380807200448, ОГРНИП:
      323385000126154, место нахождения: 664536, Иркутская область, Иркутский
      р-н, д. Лыловщина, ул. Центральная 29А), далее по тексту – Мы, а также все
      наши компании и иные лица, входящие в группу EXAYNA.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      Мы являемся администраторами и владельцами сайта https://exayna.com и
      телеграм бота https://t.me/exaynaBot (далее – «Сервис» или «Сайт») и
      осуществляем деятельность в области информационных технологий (п.1.15
      ст.284 НК РФ) путем предоставления технической возможности получения
      публичного доступа к продуктам и услугам, размещенным на Сайте, а также
      посредством оказания&nbsp; услуг по продвижению и размещению услуг по
      организации путешествий.
      <br />
      <br />
      Сайт представляет собой программное обеспечение, контент, цифровые
      материалы, а также другие объекты и сервисы, размещаемые нами через
      https://exayna.com и https://t.me/exaynaBot.&nbsp;
      <br />
      Настоящее Пользовательское соглашение (далее – «Соглашение») является
      публичной офертой нашего Сервиса. Публичная оферта – это предложение
      заключить договор с любым, кто отзовется, на условиях этого договора. Это
      означает, что используя наш Сервис, Вы принимаете (акцептуете) и
      соглашаетесь со всеми правилами и условиями, указанными в данном
      Соглашении.
      <br />
      <br />
      Мы можем время от времени менять условия Соглашения. Все изменения будут
      незамедлительно публиковаться на Сайте. Кроме того, мы можем придумать
      еще&nbsp; регламенты, положения, правила и иные документы, которые будут
      рассказывать об отдельных аспектах работы нашего Сервиса. Все такие
      документы также публикуются на Сайте и являющимися неотъемлемой частью
      Соглашения.
      <br />
      <br />
      <b>Как мы работаем</b>
      <br />
      <br />
      Самое главное и основное, что Вам нужно знать о работе нашего Сервиса это
      то, что мы являемся маркетплейсом, то есть онлайн площадкой, где
      встречаются те, кто предлагают свои услуги по организации и проведению
      путешествий (Тревел-эксперты) и те, кто этими услугами пользуется
      (Путешественники).
      <br />
      <br />
      Очень важно понимать, что мы сами не продаем туры и не занимаемся
      организацией путешествий. Мы не продаем туристические услуги и/или
      туристские продукты и не являемся туроператором или турагентом в том в
      значении, как это указано в российском законодательстве. Мы также не
      занимаемся страхованием рисков Путешественников и Тревел-экспертов.
      <br />
      <br />
      Мы – посредники. Наша работа – размещать на Сайте информацию о
      путешествиях от Тревел-экспертов, принимать оплату от Путешественников и,
      за вычетом нашего Вознаграждения, передавать такую оплату
      Тревел-экспертам. Мы также помогаем и тем, и другим решать вопросы,
      которые возникают в процессе Бронирования туров, их отмены и прочие
      связанные вопросы.
      <br />
      <br />
      Важный момент – когда Вы (Путешественник) бронируете тур на нашем Сервисе,
      Вы вступаете договорные отношения непосредственно с Тревел-экспертом, но
      не с нами. Когда Вы (Путешественник) оплачиваете тур, Вы перечисляете
      деньги на открытый нами номинальный банковский счет. Номинальный счет –
      счет-посредник: с его помощью можно проводить операции с деньгами, права
      на которые принадлежат не владельцу счета, а участникам сделки. Другими
      словами, Вы платите деньги Тревел-эксперту, но технически этот платеж
      проходит через подключенный нами платежный сервис.
      <br />
      <br />
      Наш заработок – это Вознаграждение в размере определенного процента с
      каждого тура, который был продан Тревел-экспертом на Сервисе (подробнее
      смотрите ниже раздел «Цена и порядок оплаты» Соглашения). Обратите
      внимание, мы не берем наше Вознаграждение с Путешественников, а только с
      Тревел-экспертов за оплаченные ему туры. Для Путешественников пользование
      нашим Сайтом бесплатно. Мы можем предлагать Путешественникам
      дополнительные платные услуги, но пользоваться ими или нет остается на
      усмотрение Путешественника.
      <br />
      <br />
      <b>Раздел I. Информация и правила для Путешественников</b>
      <br />
      <br />
      Как пользоваться Сайтом, если Вы Путешественник
      <br />
      <br />
      &nbsp;&nbsp;1.1. Для того, чтобы в полной мере пользоваться нашим
      Сервисом, Вы должны быть дееспособным физическим лицом, достигшим возраста
      18 лет и действующим от своего имени или же зарегистрированным в
      установленном порядке юридическим лицом или индивидуальным
      предпринимателем.&nbsp;
      <br />
      <br />
      1.2. Вы можете пользоваться Сайтом без регистрации для ознакомления с
      информацией, размещенной на Сайте.
      <br />
      &nbsp;
      <br />
      1.3. Для того, чтобы иметь возможность осуществлять Заказы и осуществлять
      Бронирование туров, Вам необходимо создать Аккаунт – уникальную учетную
      запись на Сайте, позволяющую однозначно идентифицировать Вас, как
      владельца аккаунта. При создании Аккаунта Вам необходимо будет указать о
      себе достоверные данные и сведения, которые будут запрошены Сайтом, а
      также пройти авторизацию и верификацию.
      <br />
      <br />
      1.4. В случае указания недостоверной информации, а также информации,
      которая противоречит закону, общепринятым нормам морали и нравственности,
      Мы вправе отказать Вам в регистрации Аккаунта.
      <br />
      <br />
      1.5. После создания Аккаунта, Вы получите доступ к Личному кабинету –
      нескольким специальным защищенным страницам Сайта, доступ к которым
      предоставляется Вам по учетным данным (логину и паролю или аккаунту
      телеграм). Посредством Личного кабинета Мы, Путешественник и
      Тревел-эксперты можем взаимодействовать друг с другом. Кроме того, в
      Личном кабинете отражается информация о направленных и подтвержденных
      Авторских турах, их статусе, действиях по Заказам.
      <br />
      <br />
      1.6. Через Личный кабинет Путешественник может делать Заказы на Сайте.
      Сделанный Путешественником Заказ представляет собой договор оказания
      услуг, заключенный между Путешественником и Тревел-экспертом в отношении
      тура, размещенного на Сайте. Туры, размещаемые на Сайте, также именуются
      «Авторское путешествие» или «Авторский тур».
      <br />
      <br />
      1.7. Результатом успешно сделанного Заказа является Бронирование, которое
      представляет собой согласие Путешественника с условиями тура,
      подтвержденное внесением оплаты/предоплаты за услуги Тревел-эксперта на
      условиях его Объявления.
      <br />
      <br />
      1.8. На нашем сайте Вы можете не только сделать Заказ, но и
      приобрести&nbsp;Электронный подарочный сертификат (ЭПС)&nbsp;– электронный
      документ, который может быть использован Вами или другим Путешественником
      для Бронирования тура на сумму, эквивалентную номиналу сертификата.&nbsp;
      <br />
      <br />
      <b>Что важно знать Путешественнику перед оформлением Заказа</b>
      <br />
      <br />
      2.1. Путешественник несет ответственность за:
      
    </span>
  </p>
  <ul type="disc">
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l3 level1 lfo1",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          приобретение любых принадлежностей, в том числе провизии, средств
          передвижения, мест проведения и иных материалов (далее –
          оборудование), необходимых для проведения Авторского путешествия или
          участия в нем.&nbsp;
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l3 level1 lfo1",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          понимание и соблюдение всех законов, норм, требований, обычаев,
          которые могут быть применимы к туру как законодательством Российской
          Федерации, так и законодательством страны пребывания.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l3 level1 lfo1",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          получение всех необходимых лицензий, виз, разрешений или
          регистрационных документов для участия в туре.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l3 level1 lfo1",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          своих несовершеннолетних детей, а также лиц, находящихся под опекой
          Путешественника.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l3 level1 lfo1",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          риски заболеваний, травм, физических увечий или смерти. Решая
          воспользоваться подобными Авторскими путешествиями, Путешественник
          добровольно и сознательно принимает эти риски на себя.&nbsp;
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l3 level1 lfo1",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          любые свои недобросовестные и/или противозаконные действия и/или
          бездействие.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l3 level1 lfo1",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          за своевременное предоставление тревел-эксперту достоверной информации
          об особенностях здоровья, диеты или иных индивидуальных факторах,
          влияющих в той или иной степени на участие в авторском туре.
          
        </span>
      </b>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      2.2. Путешественник, используя Сервис, дает свое согласие:
      
    </span>
  </p>
  <ul type="disc">
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l5 level1 lfo2",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          с условиями, указанными Тревел-экспертом в информации об Авторском
          туре, в том числе с условиями отказа от Бронирования и/или
          аннулирования услуг.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l5 level1 lfo2",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          с тем, что Тревел-эксперт в особых случаях, включая, но не
          ограничиваясь, таких как изменение погодных условий, вправе вносить
          изменения в программу тура, не ухудшающие ее потребительские свойства,
          с сохранением общего объема тура и без какой-либо доплаты со стороны
          Путешественника.
          
        </span>
      </b>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      <br />
      2.3. Путешественник вправе:
      
    </span>
  </p>
  <ul type="disc">
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l0 level1 lfo3",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          приобретать, получать и использовать Электронный подарочный сертификат
          Сервиса (ЭПС) в порядке и на условиях, указанных в Правилах
          использования подарочного сертификата.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l0 level1 lfo3",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          в случае если Путешественник найдет тур данного Тревел-Эксперта по
          более низкой стоимости в течение 24 часов после произведенной им
          оплаты и предоставит действующую ссылку на информацию об этом,
          Тревел-эксперт обязуется предоставить Путешественнику скидку с учетом
          которой стоимость такого Авторского путешествия не будет превышать
          стоимость ранее найденного предложения.&nbsp;
          
        </span>
      </b>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      <br />
      <br />
      <b>Как оформить Заказ</b>
      <br />
      <br />
      3.1. Перед оформлением Заказа ознакомьтесь, пожалуйста, со следующей
      информацией:
      
    </span>
  </p>
  <ul type="disc">
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          тип Авторского тура и даты его проведения.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          описание маршрута Авторского тура.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          количество участников Авторского тура.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          описание дополнительных услуг.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          перечень необходимой/рекомендуемой одежды/снаряжения для Пользователя.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          перечень предоставляемой техники и экипировки Путешественнику (если
          необходимо).&nbsp;
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          о допустимости участие в туре детей (то есть, лиц младше 18 лет).
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          о требованиях, предъявляемых уполномоченными органами к документам (в
          том числе для иностранных граждан).
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          о возможности приобретения медицинской страховки и об условиях
          страхования.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          о правилах поведения во время участия в Авторском туре.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          о возможных трудностях, связанных с незнанием языка общения страны
          (места) пребывания.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          о состоянии природной среды в стране (месте) пребывания и о специфике
          погодных условий.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          санитарно-эпидемиологической обстановке в стране (месте) временного
          пребывания.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          об опасностях, с которыми возможна встреча во время участия в
          Авторском туре.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l4 level1 lfo4",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          о том, какие необходимо осуществить меры медицинского характера (в
          т.ч. – прививки) для въезда в страну (место) временного пребывания и
          участия в Авторском туре.
          
        </span>
      </b>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      <br />
      3.1. Для оформления Заказа:
      
    </span>
  </p>
  <ul type="disc">
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l1 level1 lfo5",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          создайте Аккаунт и Личный кабинет, как это было описано выше.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l1 level1 lfo5",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          выберите Авторские туры на Сайте и бронируйте Авторский тур
          посредством нажатия соответствующей кнопки и внесением платы за
          Бронирование (если применимо). В случае необходимости, перед
          Бронированием путешествия Путешественник может связаться с
          Тревел-экспертом для уточнения деталей посредством чата на странице
          тура или с нами через форму обратной связи.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l1 level1 lfo5",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          предоставьте нам и Тревел-эксперту свои персональные и контактные
          данные (как отраженные в Личном кабинете, так и дополнительно
          запрошенные нами в процессе оформления Заказа).
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l1 level1 lfo5",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          после Бронирования Вы получите уведомление на электронную почту или
          мессенджер (если подключены соответствующие уведомления) с
          подтверждением получения Заказа. В течение 96 (девяносто шести) часов
          с момента получения указанного уведомления Вы получите подтверждение
          от Тревел-эксперта о возможности предоставления услуг по Заказу или
          уведомление об отказе в предоставления услуг. В случае такого отказа,
          плата за Бронирование (если применимо) подлежит возврату Вам в течение
          не более чем 10 (десяти) банковских дней с момента получения отказа.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l1 level1 lfo5",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          оплатите Авторский тур после получения подтверждения от
          Тревел-эксперта о возможности предоставления услуг.
          
        </span>
      </b>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      &nbsp;
      <br />
      <br />
      <b>Порядок оплаты Заказа&nbsp;</b>
      <br />
      <br />
      4.1. Путешественник обязуется оплатить Заказ не позднее 72 часов с момента
      Бронирования путешествия, в противном случае Заказ отменяется. Минимальная
      сумма предоплаты за Бронирование указана на странице Авторского тура.
      Путешественник может оплатить любую сумму предоплаты, но не меньше, чем
      указано на странице Авторского тура. Оплата второй части (постоплата)
      стоимости Заказа возможна как на сайте, для тех туров, в которых
      Тревел-эксперт разрешил такую опцию, так и напрямую Тревел-эксперту.
      <br />
      <br />
      4.2. Как было указано выше, денежные средства, уплаченные Путешественником
      за Авторский тур, перечисляются на номинальный счет в соответствии со
      ст.860.1 Гражданского кодекса Российской Федерации. Все права на денежные
      средства на номинальном счете принадлежат соответствующему Тревел-эксперту
      в объеме обязательств по договору с Путешественником.
      <br />
      <br />
      4.3.&nbsp; Виды допустимых операций по номинальному счету:
      
    </span>
  </p>
  <ul type="disc">
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l2 level1 lfo6",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          расчеты с Тревел-экспертом (бенефициаром по номинальному счету) и
          Путешественником.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l2 level1 lfo6",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          выплата Вознаграждения ИП Гарин Алексей Анатольевич.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l2 level1 lfo6",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          выплата комиссии кредитной организации за перевод денежных средств.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l2 level1 lfo6",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          перевод денежных средств на расчетный счет ИП Гарин Алексей
          Анатольевич и/или платежного агента для расчетов с Тревел-экспертами,
          являющимися налоговыми нерезидентами РФ.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l2 level1 lfo6",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          выплата страховой премии в случае оформления договора страхования
          согласно условиям, размещенным на Сайте и/или в описании Авторского
          тура.
          
        </span>
      </b>
    </li>
    <li
      className="MsoNormal"
      style={{
        color: "black",
        msoMarginTopAlt: "auto",
        msoMarginBottomAlt: "auto",
        lineHeight: "normal",
        msoList: "l2 level1 lfo6",
        tabStops: "list 36.0pt",
        background: "white"
      }}
    >
      <b>
        <span
          style={{
            fontSize: "13.5pt",
            fontFamily: '"Times New Roman",serif',
            msoFareastFontFamily: '"Times New Roman"',
            msoFareastLanguage: "RU"
          }}
        >
          выплата Вознаграждения в случае оформления рассрочки и выплаты
          процентов за приобретение Авторского тура в рассрочку, согласно
          условиям, размещенным на Сайте и/или в описании Авторского тура.
          
        </span>
      </b>
    </li>
  </ul>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      <br />
      4.4. После совершения оплаты, Путешественник получает электронное письмо
      или сообщение в боте телеграм или смс сообщение с подтверждением, в
      котором будут указаны детали Заказа и кассовый чек. Указанное электронное
      письмо является подтверждением Бронирования.&nbsp;
      <br />
      <br />
      <b>Правила отмены Бронирования и возврата денежных средств</b>
      <br />
      <br />
      5.1. Мы хотим отдельно подчеркнуть, что Путешественник нам ничего не
      платит (за исключением специальных дополнительных услуг, выбор которых
      остается на усмотрение Путешественника). Стоимость наших услуг
      оплачивается Тревел-экспертом. Стоимость наших услуг состоит из
      Вознаграждения, которое составляет не менее 10% от стоимости любого
      Авторского тура и уплачивается Тревел-экспертом, если иное не согласовано
      нами и Тревел-экспертом.
      <br />
      <br />
      5.2. Вы вправе отказаться от Авторского тура в следующих случаях:
      <br />
      <br />
      5.2.1. Вы отказались от Авторского тура в течение первых 24 часов с
      момента его Бронирования. В этом случае Вам возвращается 100% оплаченной
      стоимости Авторского тура.
      <br />
      <br />
      5.2.2. Вы выбрали услугу “Возвратный тур” (если такая услуга доступна для
      тура) за дополнительную плату сверх стоимости Авторского Тура и отказ от
      Авторского тура заявлен по прошествии первых 24 часов с момента его
      Бронирования, но не позднее чем за 24 часа до его начала. В этом случае
      Вам возвращается 100% оплаченной стоимости Авторского тура за вычетом
      стоимости услуги “Возвратный тур”. Если Вы отменяете тур позднее, чем за
      24 часа до начала тура, потраченные на данный тур бонусы и денежные
      средства не возвращаются.
      <br />
      <br />
      5.2.3. Если Тревел-эксперт отменяет неподтвержденное Бронирование. В этом
      случае Вам возвращается 100% оплаченной стоимости Авторского тура и
      стоимость услуги “Возвратный тур” в случае ее выбора.
      <br />
      <br />
      5.3. Вы вправе отказаться от тура и потребовать возврата 100% уплаченной
      Вами стоимости Авторского тура за вычетом 15% для многодневных и 20% для
      однодневных туров от полной стоимости Авторского тура, в следующих
      случаях:
      <br />
      <br />
      5.3.1. Вы отказались от Авторского тура не позднее чем за 45 дней до его
      начала за исключением случаев, когда Тревел-экспертом предусмотрен меньший
      срок для отказа,&nbsp;но не менее 15 дней.
      <br />
      <br />
      5.4. Возврат не осуществляется в следующих случаях:
      <br />
      <br />
      5.4.1. Не соблюдены условия возврата, указанные в пп.5.2 и п.5.3
      настоящего Соглашения.
      <br />
      <br />
      5.4.2. Вы отказались от Авторского тура за менее чем 15&nbsp;дней до
      начала тура.
      <br />
      <br />
      5.4.3. Если Вы не отказались от Авторского тура и при этом не поехали в
      него.
      <br />
      <br />
      5.5. Тревел-эксперт может устанавливать индивидуальные правила отмены
      Авторского тура, которые не противоречат правилам, указанным в настоящем
      Соглашении за исключением случаев, когда такие правила отмены,
      установленные Тревел-экспертом, увеличивают сумму и процент возврата
      денежных средств в пользу Путешественников по сравнению с указанными в
      настоящем Соглашении. При этом: а) мы ни при каких обстоятельствах не
      несем ответственности перед Путешественником за неспособность
      Тревел-эксперта осуществить возврат перечисленных средств б)
      индивидуальные правила отмены Авторского тура не применяются к правилам и
      размерам возврата нашего Вознаграждения, за исключением случаев, когда
      такие правила отмены, установленные Тревел-экспертом, увеличивают сумму и
      процент возврата денежных средств нашу пользу.
      <br />
      <br />
      5.6. По соглашению между Тревел-экспертом и Путешественником возврат
      денежных средств при отмене Бронирования (независимо от инициатора отмены)
      может быть осуществлен в виде Сертификата Тревел-эксперта на уплаченную
      сумму. Данный Сертификат является подтверждением обязательства
      Тревел-эксперта произвести оплату указанной в нем суммы в счет оплаты
      другого Авторского тура, выбранного Путешественником, размещенного на
      Сервисе EXAYNA данным Тревел-экспертом. Данный сертификат действителен в
      течение 2-х лет, если более продолжительный срок не указан в самом
      Сертификате.
      <br />
      <br />
      <b>Порядок оформления возврата денежных средств</b>
      <br />
      <br />
      6.1. Для получения возврата в соответствии с правилами отмены
      Путешественник должен заявить об отмене Бронирования. При необходимости
      Путешественник может связаться с Тревел-экспертом или менеджером в
      мессенджере телеграм, чтобы уточнить детали отмены и возврата
      оплаты/предоплаты.
      <br />
      <br />
      6.2. Возврат денежных средств Путешественнику осуществляется в течение 60
      (шестидесяти) дней с даты подтверждения возврата на банковский счет
      Путешественника, с которого была произведена оплата тура. Возврат
      осуществляется в размере, определенном согласно настоящему Соглашению.
      <br />
      <br />
      6.3. После списания денежных средств в счет возврата Путешественника с
      нашего банковского счета или с банковского счета Тревел-эксперта, все наши
      обязательства и обязательства Тревел-эксперта считаются исполненным.
      <br />
      <br />
      <b>Раздел II. Информация и правила для Тревел-экспертов</b>
      <br />
      <br />
      <b>Общие правила сотрудничества с Тревел-экспертами</b>
      <br />
      <br />
      Если Вы – Тревел-эксперт и желаете или уже работаете с нами, пожалуйста,
      ознакомьтесь с настоящим Соглашением.
      <br />
      <br />
      7.1. Стать Тревел-экспертом может самозанятый, не имеющий действующих
      существенных просроченных задолженностей, а также неснятых и непогашенных
      судимостей,&nbsp; имеющий соответствующий релевантный опыт и
      соответствующие квалификацию, разрешение или лицензию для организации и
      проведения путешествий.
      <br />
      <br />
      7.2. Для предложения своих услуг Путешественникам Тревел-эксперт
      регистрирует Аккаунт и Личный кабинет на Сайте и размещает объявления с
      предложением Авторского путешествия.
      <br />
      <br />
      7.3. После успешного прохождения верификации Аккаунта и Личного кабинета,
      Мы предоставляем Тревел-эксперту неисключительную лицензию на
      использование Сайта, а Тревел-эксперт обязуется соблюдать условия
      настоящего Соглашения.
      <br />
      <br />
      7.4. На основании настоящего Соглашения Мы оказываем Тревел-экспертам
      информационные услуги, связанные с размещением Авторских туров,
      формированием заказов и осуществляем организацию работы&nbsp; платежного
      агента при оплате Авторских туров Путешественниками, а Тревел-эксперт
      обязуется принять результаты оказанных услуг и выплачивать Вознаграждение
      в пользу ИП Гарин Алексей Анатольевич в размере и в порядке,
      предусмотренном настоящим Соглашением.
      <br />
      <br />
      <b>Права и обязанности Тревел-экспертов</b>
      <br />
      <br />
      8.1. Тревел-эксперт обязуется предоставить по нашему запросу документы,
      сведения и информацию.
      <br />
      <br />
      8.2. Тревел-эксперт вправе устанавливать рассрочку на оплату Авторских
      туров для Пользователей.&nbsp;
      <br />
      <br />
      8.3. Тревел-эксперт обязуется выплачивать в пользу ИП Гарин Алексей
      Анатольевич Вознаграждение в установленные настоящим Соглашением порядке и
      сроке.&nbsp;
      <br />
      <br />
      8.4. Тревел-эксперт обязуется предоставить ИП Гарин Алексей Анатольевич
      информацию о фактически оказанных им услугах по каждой Сделке (Заказе) с
      Путешественником, в объеме, необходимом ИП Гарин Алексей Анатольевич.
      Указанная информация предоставляется ИП Гарин Алексей Анатольевич&nbsp; в
      течение 3 (трех) рабочих дней с даты направления соответствующего запроса.
      <br />
      <br />
      8.5. Информация и документы, подтверждающие фактически произведенные
      Тревел-экспертом расходы, предоставляются не позднее 14 (четырнадцати)
      календарных дней с момента направления запроса Тревел-Эксперту.
      <br />
      <br />
      8.6.&nbsp;Тревел-эксперт обязуется самостоятельно и надлежащим образом
      исполнить обязательства перед Путешественниками, а также самостоятельно
      урегулировать связанные с такими обязательствами любые претензии
      Путешественников.
      <br />
      <br />
      8.7. Тревел-эксперт обязуется нести ответственность за правомерность и
      легитимность оказываемых им услуг в рамках Авторских туров и сопутствующих
      услуг, а также за безопасность путешественников.
      <br />
      <br />
      8.8. Тревел-эксперт обязуется в течение 3 (трех) календарных дней извещать
      ИП Гарин Алексей Анатольевич о любых изменениях, в том числе: изменениях
      своего фирменного наименования, места нахождения, почтового адреса,
      банковских реквизитов, адреса интернет-сайта, применяемой системы
      налогообложения, а также паспортных данных.
      <br />
      <br />
      8.9. Тревел-эксперт гарантирует, что стоимость любого из его Авторских
      туров на Сервисе не больше, чем на любом другом ресурсе в сети Интернет,
      включая, но не ограничиваясь его собственным веб-сайтом или страницами
      либо аккаунтами в социальных сетях, таких как pinterest, youtube,
      vkontakte и других. В случае если Путешественник найдет путешествие
      данного Тревел-эксперта по более низкой стоимости на протяжении 24 часов
      после произведенной им оплаты и предоставит действующую ссылку на
      информацию об этом, Тревел-эксперт обязуется незамедлительно снизить
      стоимость путешествия на Сервисе и компенсировать эту разницу
      Путешественнику.
      <br />
      <br />
      8.10. В случае если информация (контент), размещаемая Тревел-экспертом,
      охраняется авторским правом, права на такую информацию сохраняются за
      Тревел-экспертом, разместившим такую информацию. При этом Тревел-эксперт
      предоставляет Пользователям Сервиса EXAYNA безвозмездное неисключительное
      право на использование такого контента путем просмотра, воспроизведения (в
      том числе копирования), переработку (в том числе на распечатку копий) и
      иные права исключительно с целью личного некоммерческого использования,
      кроме случаев, когда такое использование причиняет или может причинить
      вред охраняемым законом интересам правообладателя.
      <br />
      <br />
      8.11. Тревел-эксперт обязуется не позднее 3 (трех) дней с даты запроса
      предоставлять ИП Гарин Алексей Анатольевич документы, подтверждающие
      правомерность и гарантии проведения Авторских туров в обозначенную
      Тревел-экспертом дату, а также подтверждающих правомерность и полномочия
      Тревел-эксперта на совершение сделок.&nbsp;
      <br />
      <br />
      <b>
        Правила размещения объявлений об Авторских турах и правила пользования
        Сайтом
      </b>
      <br />
      <br />
      9.1. Тревел-эксперт размещает на Сайте информацию об Авторских турах,
      дополнительных услугах и сопутствующих товарах. Тревел-эксперт может
      редактировать информацию об Услугах в Личном кабинете.
      <br />
      <br />
      9.2. Тревел-эксперт гарантирует, что сведения об Авторских турах и
      сопутствующих услугах, являются достоверными, полными и актуальными как на
      момент добавления, так и в последующем.
      <br />
      <br />
      9.3. При размещении информации об Авторском туре и сопутствующих услугах
      Тревел-эксперт обязуется указывать следующую информацию:
      <br />
      <br />
      9.3.1. тип авторского тура и описание мероприятий по датам.
      <br />
      <br />
      9.3.2. описание маршрута авторского тура.
      <br />
      <br />
      9.3.3. минимальное и максимальное количество участников авторского тура и
      ограничение по возрасту участников тура (если применимо).
      <br />
      <br />
      9.3.4. описание дополнительных услуг.
      <br />
      <br />
      9.3.5. условия возврата денежных средств. Данные условия не должны
      противоречить условиям настоящего Соглашения. Мы вправе удержать в свою
      пользу Вознаграждение за сформированный Заказ.
      <br />
      <br />
      9.3.6. перечень необходимой/рекомендуемой одежды/снаряжения для
      Пользователя (если необходимо).
      <br />
      <br />
      9.3.7. перечень предоставляемой техники и экипировки Путешественнику (если
      необходимо).&nbsp;
      <br />
      <br />
      9.3.8. о требованиях, предъявляемых уполномоченными органами к документам
      (в том числе для иностранных граждан).
      <br />
      <br />
      9.3.9. о возможности приобретения медицинской страховки и об условиях
      страхования.
      <br />
      <br />
      9.3.10. о правилах поведения во время участия в Авторском туре.
      <br />
      <br />
      9.3.11. о возможных трудностях, связанных с незнанием языка общения страны
      (места) пребывания.
      <br />
      <br />
      9.3.12. о состоянии природной среды в стране (месте) пребывания и о
      специфике погодных условий.
      <br />
      <br />
      9.3.13. o санитарно-эпидемиологической обстановке в стране (месте)
      временного пребывания.
      <br />
      <br />
      9.3.14. об опасностях, с которыми возможна встреча во время участия в
      Авторском туре.
      <br />
      <br />
      9.3.15. о том, какие необходимо осуществить меры медицинского характера (в
      т.ч. – прививки) для въезда в страну (место) временного пребывания и
      участия в Авторском туре.
      <br />
      <br />
      9.4. В описании услуг Тревел-экспертам запрещено использовать:
      <br />
      <br />
      9.4.1. информацию или материалы, противоречащие законодательству РФ.
      <br />
      <br />
      9.4.2. нецензурные выражения, оскорбления.
      <br />
      <br />
      9.4.3.контакты и личные данные Тревел-эксперта или его представителя для
      оформления Заказа не на Сайте.&nbsp;
      <br />
      <br />
      9.4.4. тексты, затрудняющие восприятие информации, в том числе набранные
      верхним регистром, латиницей, с большим количеством ошибок, без знаков
      препинания или пробелов.
      <br />
      <br />
      9.4.5. информацию о проведении т.н. секс-туров или иных аналогичных туров,
      пропаганды употребления наркотических средств, алкоголя, табачных изделий
      и/или электронных сигарет, пропаганда ЛГБТ, туров, представляющих
      потенциальную угрозу или опасность для путешественников (например, туры в
      коллекторы, по крышам и тд).
      <br />
      <br />
      9.4.6. тексты, изображения и другие объекты, права на которые принадлежат
      другим лицам и права на использование которых отсутствуют у
      Тревел-эксперта.
      <br />
      <br />
      9.5. Тревел-эксперту запрещается:
      <br />
      <br />
      9.5.1. Использовать Сайт способами, прямо не предусмотренными настоящим
      Соглашением.
      <br />
      <br />
      9.5.2. Пытаться получить доступ к личной информации других
      Тревел-экспертов любым способом, в том числе, но не ограничиваясь, путем
      обмана, злоупотребления доверием или взлома Сайта.
      <br />
      <br />
      9.5.3. Предпринимать любые действия, в том числе технического характера,
      направленные на нарушение нормального функционирования Сайта.
      <br />
      <br />
      9.5.4. Использовать любые технические средства для сбора и обработки
      информации на Сайте, включая персональные данные Пользователей и других
      Тревел-экспертов.
      <br />
      <br />
      9.5.5. Копировать, вносить изменения, подготавливать производные
      материалы, декомпилировать, пытаться вскрыть исходный код или каким-либо
      иным способом изменять Сайт.
      <br />
      <br />
      9.5.6. Любым способом вводить других Тревел-экспертов, Пользователей,
      Администрацию в заблуждение.
      <br />
      <br />
      9.5.7. Выдавать себя за другое лицо, его представителя, без достаточных на
      то прав, в том числе за ИП Гарин Алексей Анатольевич или его сотрудников,
      а также применять любые другие формы и способы незаконного
      представительства других лиц.
      <br />
      <br />
      9.5.8. Использовать информацию о полученных телефонах, почтовых адресах,
      адресах электронной почты для рассылки спама, т.е. сообщений коммерческого
      и некоммерческого характера.
      <br />
      <br />
      9.5.9. Использовать логотипы, товарные знаки и/или другие коммерческие
      обозначения EXAYNA без разрешения.
      <br />
      <br />
      9.5.10. Предоставлять доступ к Аккаунту третьим лицам. Тревел-эксперт
      самостоятельно несет ответственность за передачу доступа к Аккаунту
      третьим лицам.
      <br />
      <br />
      9.5.11. Принимать от Путешественников оплату без уплаты Вознаграждения ИП
      Гарин Алексей Анатольевич.
      <br />
      <br />
      9.5.12. Изменять Условия отмены по туру, в случае если депозит уже получен
      Тревел-экспертом.
      <br />
      <br />
      9.5.13. Использовать изображения, видео и другой контент, принадлежащим
      третьим лицам без согласия таких лиц и размещать такой контент на Сайте.
      <br />
      <br />
      9.6. В случае нарушений правил нашего Сервиса мы оставляем за собой право
      в одностороннем порядке и по своему усмотрению: 1) повысить размер
      комиссии в пределах 10% 2) временно и полностью ограничить доступ к
      Сервису 3) удерживать из сумм, причитающихся Тревел-эксперту суммы,
      взысканные с нас в судебном или ином порядке любыми третьими лицами
      вследствии нарушения Тревел-экспертом положений настоящей Оферты, 4)
      взыскивать все комиссии платформы.
      <br />
      <br />
      <b>Порядок осуществления расчетов</b>
      <br />
      <br />
      10.1. В соответствии со ст. 860.1 ГК РФ и в рамках настоящего соглашения
      все платежные операции производятся с помощью номинального счета
      (банковского счета).
      <br />
      <br />
      10.2. Права на денежные средства на номинальном счете принадлежат
      Тревел-эксперту в объеме обязательств по заключенным договорам оказания
      услуг с Путешественниками.
      <br />
      <br />
      10.3. Номинальный счет не используется для исполнения Тревел-экспертами
      своих обязательств по уплате налогов. Для указанных целей стороны
      используют свои текущие банковские счета в обычном порядке.&nbsp;
      Обязанности по уплате налогов лежит на самом Тревел-эксперте.
      <br />
      <br />
      10.4. Не позднее 30 (тридцати) банковских дней для Тревел-экспертов с даты
      подтверждения Заказа Тревел-экспертом денежные средства, полученные от
      Пользователей, поступают с номинального счета на расчетный счет
      Тревел-эксперта, за вычетом Вознаграждения Нам.
      <br />
      <br />
      10.5. Вознаграждение ИП Гарин Алексей Анатольевич составляет 10% от
      стоимости услуг Тревел-эксперта для туров длительностью более одного
      календарного дня и 10% для однодневных туров, обозначенных как стоимость
      участия в Авторском туре, если иное не установлено в отдельных соглашениях
      с Тревел-экспертом. В случае если Тревел-эксперт является нерезидентом
      Российской Федерации, то при выплате в пользу такого Тревел-эксперта
      осуществляется дополнительное удержание из перечисляемой суммы для
      компенсации наших расходов и транзакционных издержек в размере курса
      Центрального банка Российской Федерации на дату платежа по отношении к
      валюте платежа плюс 4%.
      <br />
      <br />
      10.6. Вознаграждение ИП Гарин Алексей Анатольевич не облагается НДС в
      связи с применением упрощенной системы налогообложения в соответствии с ч.
      2 ст. 346.11 НК РФ.
      <br />
      <br />
      10.7. Расчеты производятся в российских рублях. Обязательство
      Тревел-эксперта считается исполненным с момента надлежащего и в полном
      объеме оказания услуг Путешественникам и поступления Вознаграждения на
      счет ИП Гарин Алексей Анатольевич.
      <br />
      <br />
      10.8. ИП Гарин Алексей Анатольевич&nbsp; вправе не перечислять денежные
      средства до момента указания Тревел-экспертом своих реквизитов в Личном
      кабинете. При этом не допускается указание реквизитов, принадлежащим
      третьим лицам. Штрафные санкции за задержку платежа не начисляются и не
      оплачиваются ИП Гарин Алексей Анатольевич.
      <br />
      <br />
      10.9. Тревел-эксперт предоставляющий услуги как самозанятый самостоятельно
      уплачивает все и любые налоги и сборы с доходов, полученных за услуги и
      сопутствующие товары. Размещая тур на нашем Сервисе Тревел-эксперт
      обязуется зарегистрироваться как самозанятый до момента получения денежных
      средств за его тур, если он еще не является самозанятым. Тревел-эксперт
      обязуется предоставить ИП Гарин Алексей Анатольевич достоверную информацию
      о применяемой Тревел-экспертом системе налогообложения, и в случае
      получения от ИП Гарин Алексей Анатольевич соответствующего запроса,
      предоставить документы, подтверждающие факт применения Тревел-экспертом
      соответствующей системы налогообложения.&nbsp;
      <br />
      <br />
      10.10. Услуга может быть оплачена Путешественником посредством внутренней
      рассрочки согласно условиям партнера ИП Гарин Алексей Анатольевич.
      <br />
      <br />
      10.11. Оплата Услуг посредством внутренней рассрочки производится
      ежемесячно, равными частями от стоимости услуг. Размер ежемесячного
      платежа определяется соглашением Тревел-эксперта и Пользователя в
      зависимости от итоговой стоимости Услуг.
      <br />
      <br />
      10.12. Дата ежемесячного платежа по внутренней рассрочке устанавливается в
      зависимости от внесения Пользователем первого платежа на оплату услуг
      Тревел-эксперта – датой платежа является аналогичное число каждого
      последующего месяца за внесением первого платежа.
      <br />
      <br />
      10.13. В случае отсутствия ежемесячного платежа по внутренней рассрочке в
      течение 1 календарного месяца после наступления даты оплаты Тревел-эксперт
      вправе отказать в оказании Услуги Путешественнику.
      <br />
      <br />
      10.14. При оплате заказа банковской картой, обработка платежа (включая
      ввод номера карты) происходит на защищенной странице процессинговой
      системы, которая прошла международную сертификацию. Это значит, что Ваши
      конфиденциальные данные (реквизиты карты, регистрационные данные и др.) не
      поступают в интернет-магазин, их обработка полностью защищена и никто, в
      том числе наш интернет-магазин, не может получить персональные и
      банковские данные клиента.
      <br />
      <br />
      10.15. При работе с карточными данными применяется стандарт защиты
      информации, разработанный международными платёжными системами&nbsp;
      Visa&nbsp; и&nbsp; MasterCard&nbsp; – Payment Card Industry Data Security
      Standard (PCI DSS), а также ПС МИР (Mir Accept), что обеспечивает
      безопасную обработку реквизитов Банковской карты Держателя. Применяемая
      технология передачи данных гарантирует безопасность по сделкам с
      Банковскими картами путем использования протоколов TLS (Transport Layer
      Security), Verified by Visa, Secure Code, MIR Accept и закрытых банковских
      сетей, имеющих высшую степень защиты.
      <br />
      <br />
      10.16. В случае возврата, денежные средства возвращаются на ту же карту, с
      которой производилась оплата если нами не согласовано иное.
      <br />
      <br />
      <b>Отчет Тревел-эксперта</b>
      <br />
      <br />
      11.1. По окончании отчетного периода Тревел-эксперт подтверждает ИП Гарин
      Алексей Анатольевич информацию об оплатах, которые Пользователи
      осуществляли Тревел-эксперту. ИП Гарин Алексей Анатольевич передает всю
      необходимую информацию о подтверждении сделки, а Тревел-эксперт все
      необходимые данные для получения денежных средств. ИП Гарин Алексей
      Анатольевич не гарантирует отсутствие ошибок и сбоев в работе в отношении
      предоставления возможности оплаты.
      <br />
      <br />
      11.2. Исполненным считается заказ, по которому Тревел-экспертом оказана
      Услуга Путешественнику и по которому отсутствуют жалобы/замечания со
      стороны Путешественника. В отношении сформированных и исполненных заказов
      ИП Гарин Алексей Анатольевич указывает размер Вознаграждения за оказанные
      информационные услуги Тревел-эксперту.
      <br />
      <br />
      11.3. В отчете указываются:
      <br />
      <br />
      11.3.1.&nbsp; номера исполненных заказов.
      <br />
      <br />
      11.3.2. сведения о Путешественниках.
      <br />
      <br />
      11.3.3. размер оплаты Тревел-эксперта.
      <br />
      <br />
      11.3.4. размер Вознаграждения ИП Гарин Алексей Анатольевич.
      <br />
      <br />
      11.4. Тревел-эксперт вправе направить свои письменные возражения по отчету
      не позднее 3 (трех) рабочих дней с момента его получения.&nbsp;
      <br />
      <br />
      11.5. Если Тревел-эксперт в указанный срок не направит Нам письменные
      возражения на адрес электронной почты Нам, отчет считается принятым
      Тревел-экспертом, а вся первичная документация (акты, отчеты) считается
      автоматически подписанной Тревел-экспертом и является юридически значимой.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      Условия и порядок прекращения оказания услуг Тревел-экспертом
      <br />
      <br />
      12.1. Правоотношения между Тревел-экспертом и ИП Гарин Алексей Анатольевич
      возникают с момента его присоединения к условиям настоящего Соглашения и
      действуют в течение неопределенного срока. Правоотношения сторон
      прекращаются в случае, если:
      <br />
      <br />
      12.1.1. Тревел-эксперт принял решение о прекращении использования Сервиса,
      направив ИП Гарин Алексей Анатольевич соответствующее уведомление
      (посредством обращения через интерфейс Сервиса).
      <br />
      <br />
      12.1.2. ИП Гарин Алексей Анатольевич примет решение о прекращении
      правоотношений в одностороннем внесудебном порядке с немедленным
      прекращением доступа и возможности использовать Сервис и без возмещения
      каких-либо затрат или убытков, если иное не предусмотрено применимым
      законодательством.
      <br />
      &nbsp;
      <br />
      12.2. Тревел-эксперт вправе отказаться от оказания услуг Путешественнику в
      срок, не превышающий 24 часов с момента получения запроса на оказание
      Услуг в рамках Авторского тура, или до момента внесения оплаты
      Путешественником за такой заказ. В случае если срок для отказа
      Тревел-эксперта от Договора не превышает 96 часов, ИП Гарин Алексей
      Анатольевич осуществляет автоматический возврат внесенной суммы за заказ
      Путешественнику без участия Тревел-эксперта.
      <br />
      <br />
      12.3. В случае отказа Путешественника от оказания услуг Тревел-эксперт
      возвращает полученные денежные средства ИП Гарин Алексей Анатольевич в
      полном объеме в течение 3 (Трех) рабочих дней, если иное не установлено в
      описании к Авторскому туру и Заказу. ИП Гарин Алексей Анатольевич
      осуществляет возврат денежных средств Путешественнику в соответствии с
      пунктом 6.2. настоящей Оферты.
      <br />
      <br />
      12.4. Если от Тревел-эксперта поступит информация о невозможности оказания
      забронированной и/или подтвержденной Услуги или об изменении параметров
      забронированной Услуги, то ИП Гарин Алексей Анатольевич предлагает
      Путешественнику альтернативные Услуги.
      <br />
      <br />
      12.5. Если Тревел эксперт не оказал услуги или не предоставил оплаченные
      сопутствующие товары Путешественнику по причинам, не зависящим от
      Путешественника, денежные средства подлежат возврату Тревел-экспертом
      Путешественнику в полном объеме за вычетом документально подтвержденных
      расходов в течение трех рабочих дней.
      <br />
      <br />
      12.6. Условия отмены, установленные Тревел-экспертом самостоятельно и
      индивидуально для каждого тура и актуальные на момент внесения депозита,
      не подлежат изменению в течение всего срока действия Тура.
      <br />
      <br />
      <b>Ответственность Тревел-эксперта и порядок разрешения споров</b>
      <br />
      <br />
      13.1. В случае предъявления ИП Гарин Алексей Анатольевич претензий и исков
      со стороны третьих лиц в связи с нарушением Тревел-экспертом норм
      действующего законодательства РФ, условий Договора и/или Договора оказания
      услуг:
      <br />
      <br />
      13.1.1. Тревел-эксперт обязан самостоятельно, за свой счет и в разумный
      срок урегулировать указанные претензии и споры без привлечения ИП Гарин
      Алексей Анатольевич&nbsp; в качестве стороны в споре.
      <br />
      <br />
      13.1.2. ИП Гарин Алексей Анатольевич вправе оплатить предъявленные
      требования в добровольном порядке и потребовать от Тревел-эксперта
      возмещения понесенных убытков, возникших вследствие предъявления к ней
      претензий и исков со стороны третьих лиц. ИП Гарин Алексей Анатольевич
      вправе удержать в свою пользу сумму документально подтвержденных убытков
      из денежных средств, подлежащих перечислению Тревел-эксперту по Договору
      услуг (Заказу).
      <br />
      <br />
      13.1.3. ИП Гарин Алексей Анатольевич вправе возмещать понесенные убытки
      и/или транзакционные издержки в случае возврата денежных средств
      Путешественнику из любых сумм, поступивших на номинальный счет для
      исполнения Заказа (договора оказания услуг), заключенного Пользователем и
      Тревел-экспертом.
      <br />
      <br />
      13.2. Сайт предоставляется Тревел-эксперту “КАК ЕСТЬ” (“AS IS”) в
      соответствии с общепринятым в международной практике принципом. Это
      означает, что ИП Гарин Алексей Анатольевич не несет ответственности и не
      возмещает убытки Тревел-эксперта за проблемы, возникающие в процессе
      эксплуатации Сайта, в том числе:
      <br />
      <br />
      13.2.1. проблемы, связанные с некорректной работой Сайта.
      <br />
      <br />
      13.2.2. проблемы, возникающие в результате неправомерных действий третьих
      лиц и т.п.
      <br />
      <br />
      13.2.3. невозможность использования Сайта по причинам, не зависящим от ИП
      Гарин Алексей Анатольевич.
      <br />
      <br />
      13.3. ИП Гарин Алексей Анатольевич не несет ответственности перед
      Тревел-экспертом за:
      <br />
      <br />
      13.3.1. убытки Тревел-эксперта.
      <br />
      <br />
      13.3.2. предоставление Тревел-экспертом услуг Путешественникам.
      <br />
      <br />
      13.3.3. несоответствие стоимости Услуг, указанной на Сайте, действующим
      прейскурантам Тревел-эксперта в случае, если Тревел-эксперт не уведомлял
      об изменении стоимости Услуг.
      <br />
      <br />
      13.3.4. любые действия Путешественника.
      <br />
      <br />
      13.4. В случае, если Путешественник оплатил Авторский тур, дополнительные
      услуги или сопутствующие товары непосредственно Тревел-эксперту, а
      Тревел-эксперт не оплатил Вознаграждение ИП Гарин Алексей Анатольевич, ИП
      Гарин Алексей Анатольевич вправе расторгнуть договор с Тревел-экспертом в
      одностороннем порядке и заблокировать аккаунт и Личный кабинет
      Тревел-эксперта.
      <br />
      <br />
      13.5. ИП Гарин Алексей Анатольевич вправе в одностороннем порядке без
      направления уведомления отказаться от исполнения настоящего Соглашения и
      удалить Аккаунт Тревел-эксперта в случае нарушения наших правил.
      <br />
      <br />
      <b>
        Раздел III. Общая информация для Тревел-экспертов и путешественников
      </b>
      <br />
      <br />
      <b>Интеллектуальная собственность</b>
      <br />
      <br />
      14.1. Все исключительные права на Сервис, включая, но не ограничиваясь,
      программы ЭВМ, базы данных, интерфейс, технические разработки, логотип,
      товарный знак, иные средства индивидуализации, используемые на Сервисе
      EXAYNA и позволяющие реализовывать функциональные возможности Сервиса
      EXAYNA, принадлежат Нам.
      <br />
      <br />
      14.2. Кроме случаев, установленных Соглашением, а также действующим
      законодательством Российской Федерации, наш Сервис и любые его материалы,
      в том числе перечисленные в пункте выше, не могут быть скопированы
      (воспроизведены), переработаны, распространены, отображены во фрейме,
      опубликованы, скачаны, переданы, проданы или иным способом использованы
      целиком или по частям без нашего предварительного письменного согласия.
      <br />
      <br />
      14.3. В случае если информация, изображения и любой иной контент,
      размещаемый пользователями на нашем Сервисе, охраняется авторским правом,
      то права на такую информацию, изображения и любой иной контент сохраняются
      за пользователем ее разместившим. При этом Путешественник и Тревел-эксперт
      предоставляют нам безвозмездное неисключительное право (лицензию) на
      использование такого контента путем просмотра, воспроизведения (в том
      числе копирования), переработку (в том числе на распечатку копий) и иные
      права.
      <br />
      <br />
      14.4. Путешественник предоставляет Нам неисключительное право использовать
      на безвозмездной основе размещенный на Сервисе и принадлежащий ему на
      законных основаниях контент в целях обеспечения нами функционирования
      Сервиса в объеме, определяемом его функционалом, а также отображения
      контента в наших промо-материалах (рекламных материалах), в том числе в
      рамках изображений интерфейса Сервиса.
      <br />
      <br />
      14.5. Указанное неисключительное право предоставляется нам на весь срок
      действия исключительного права пользователя, включает в себя в т.ч. право
      воспроизведения контента, а также переработки контента, в том числе путем
      включения в состав сложного объекта или составного произведения,
      последующего показа, доведения до всеобщего сведения, сообщения по кабелю
      и т.п. и распространяет свое действие на территории стран всего мира.
      <br />
      <br />
      14.6. Мы вправе передавать права, указанные в настоящей статье, третьим
      лицам в целях, указанных в настоящей статье, без согласия лица, владеющего
      исключительными правами. Окончание срока размещения контента на Сервисе
      и/или срока действия неисключительного права не влечет за собой
      необходимость изъятия из оборота наших промо-материалов (рекламных
      материалов) с отображением контента (в том числе их удаление из сети
      Интернет).
      <br />
      <br />
      14.7. Никакие положения Соглашения не предоставляют Путешественникам и/или
      Тревел-экспертам право на использование нашего логотипа, фирменного
      наименования, товарных знаков, доменных имен и иных реквизитов, и
      отличительных знаков.
      <br />
      <br />
      <b>Наша ответственность и порядок разрешения споров</b>
      <br />
      <br />
      15.1. Мы не несем ответственности за любые возможные незаконные действия
      Тревел-экспертов относительно Путешественников и/или любых иных третьих
      лиц.
      <br />
      <br />
      15.2. Мы не несем ответственности за задержки или сбои в процессе
      совершения операции, возникшие вследствие непреодолимой силы, а также
      любого случая неполадок в телекоммуникационных, компьютерных,
      электрических и иных смежных системах.
      <br />
      <br />
      15.3. Мы не несем ответственности за действия систем переводов, банков,
      платежных систем и за задержки, связанные с их работой.
      <br />
      <br />
      15.4. Мы не несем ответственность за достоверность информации, указанной
      Путешественником и Тревел-экспертом на Сайте.
      <br />
      <br />
      15.5. Мы не несем ответственности за неисполнение Тревел-экспертом любых
      денежных и иных обязательств перед Путешественником.
      <br />
      <br />
      15.6. Из-за того, что мы не являемся лицом, обеспечивающим техническую
      возможность непрерывного доступа к сети Интернет для пользователей и для
      себя, мы не можем гарантировать постоянную и непрерывную доступность
      Сервиса пользователям. Мы вправе ограничить доступность пользователям
      Сервиса EXAYNA, ее разделов или функций, если это будет необходимо для
      поддержания должной пропускной способности, безопасности или целостности
      его серверов, а также для проведения технических мероприятий,
      обеспечивающих надлежащее или улучшенное функционирование Сервиса, а также
      в случае возникновения или проведения технических или регламентных работ
      на стороне банка, осуществляющего обслуживание ИП Гарин Алексей
      Анатольевич, мы имеем право временно осуществить перевод денежных средств,
      размещенных на номинальном счете, на свой расчетный счет.
      <br />
      <br />
      15.7. Все споры, которые возникнут между сторонами и в ходе исполнения
      Договора, разрешаются путем переговоров.
      <br />
      <br />
      15.8. Претензионный порядок урегулирования споров, вытекающих из
      исполнения Договора, является для Сторон обязательным. Срок рассмотрения
      претензии составляет 30 (тридцать) рабочих дней со дня ее получения.
      <br />
      <br />
      15.9. В случае невозможности достижения согласия путем переговоров или в
      претензионном порядке, споры решаются в судебном порядке в соответствии с
      законодательством РФ по месту нахождения ИП Гарин Алексей Анатольевич.
      <br />
      <br />
      <b>Правила размещения фотографий и другого контента на Сайте</b>
      <br />
      <br />
      Мы уделяем большое внимание содержанию контента, который публикуется
      пользователями (Путешественниками и Тревел-экспертами) на нашем Сайте.
      <br />
      <br />
      <b>
        Мы убедительно просим всех пользователей нашего сайта и особенно
        Тревел-экспертов внимательно ознакомиться с информацией, изложенной
        ниже:
      </b>
      <br />
      <br />
      – Нельзя просто так взять фотографию или любой другой аналогичный контент
      из интернета и разместить на Сайте.
      <br />
      – Все права на фотографии принадлежат авторам, то есть тем, кто такие
      фотографии сделал (например, фотограф) или лицам, представляющим интересы
      авторов (различные агентства и прочие).
      <br />
      – Тот факт, что кто-то разместил фото в интернете и Вы его можете скачать
      и выложить на Сайте не означает, что Ваши действия являются законными.
      <br />
      – Перед публикацией на нашем Сайте любых фотографий или любого иного
      контента, защищенного авторским правом, Вы обязаны получить письменное
      согласие на такое использование от автора фотографии или иного
      уполномоченного представителя автора и предоставить его нам.&nbsp;
      <br />
      – Согласия не требуется только в двух случаях: 1) Вы размещаете
      фотографии, сделанные Вами лично 2) Вы берете фотографии из специальных
      интернет-ресурсов (банков фотографий), которые предоставляют т.н. открытую
      лицензию, то есть право использовать фотографии, как угодно. При этом Вы
      обязаны при размещении таких фотографий на Сайте указать источник (сайт)
      откуда эти фотографии взяты.
      <br />
      – В случае нарушения любого из вышеуказанных правил, аккаунты любого
      пользователя нашего Сайте будут заблокированы.
      <br />
      <br />
      16.1. Мы является информационным посредником в порядке ст.1253.1
      Гражданского кодекса Российской Федерации и не несем ответственности за
      нарушение&nbsp;авторских, смежных прав и иных прав на объекты
      интеллектуальной собственности, допущенных Тревел-экспертами и
      Путешественниками при размещении информации об услугах на Сайте. Мы
      презюмируем добросовестное отношение Тревел-экспертов к информации и
      изображениям, размещаемых ими на Сайте.
      <br />
      <br />
      16.2.&nbsp; В случае предъявления к нам требований денежного характера,
      вытекающих из нарушения авторских и смежных прав, допущенного
      Тревел-экспертом и/или Путешественником, мы вправе перевыставить указанные
      требования в полном объеме к Тревел-эксперту, а также взыскать с него
      убытки, вызванные указанным нарушением.
      <br />
      Персональные данные
      <br />
      <br />
      17.1. Предоставляя свои персональные данные и выражая согласие с настоящим
      договором, пользователь нашего Сайта дает свое согласие на обработку,
      хранение и использование своих персональных данных на основании ФЗ №
      152-ФЗ «О персональных данных» от 27.07.2006 г. в следующих целях:
      <br />
      <br />
      17.1.1. регистрации на сайте Сервиса EXAYNA
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      17.1.2. осуществления клиентской поддержки технической службой
      сайта.&nbsp;
      <br />
      <br />
      17.1.3. организации расчетов в соответствии с настоящим Соглашением.
      <br />
      <br />
      17.1.4. проведения нами аудита и прочих внутренних исследований с целью
      повышения качества предоставляемых услуг.
      <br />
      <br />
      17.1.5. предоставления сведений о пользователе как о лице, разместившем на
      Сервисе контент с нарушением авторских прав третьих лиц в случае обращения
      лиц, чьи права были нарушены Пользователем.
      <br />
      <br />
      17.2. Принимая условия настоящего Соглашения, Вы признаете и
      подтверждаете, что в случае необходимости предоставления своих
      персональных данных в соответствии с требованиями действующего
      законодательства Российской Федерации для достижения указанных выше целей
      третьим лицам, мы вправе в необходимом объеме раскрывать таким третьим
      лицам для совершения вышеуказанных действий информацию о нем (включая его
      персональные данные), а также предоставлять соответствующие документы. При
      этом указанные выше третьи лица имеют право на обработку его персональных
      данных на основании настоящего согласия.
      <br />
      <br />
      <b>Заключительные положения</b>
      <br />
      <br />
      18.1. Никакие положения настоящего Соглашения не могут считаться
      установлением между Тревел-экспертами и нами трудовых правоотношений,
      совместного предприятия, а также иных правоотношений, не урегулированных
      настоящим Соглашением.
      <br />
      <br />
      18.2. В случае признания какого-либо из положений настоящего Соглашения
      недействительным или неисполнимым, данное положение будет считаться
      удаленным и не будет затрагивать действительность и исполнимость остальных
      положений.
      <br />
      <br />
      18.3. Если не указано иное, все уведомления или иные сообщения,
      направление которых разрешается или требуется настоящими Соглашением,
      оформляются в электронной форме и направляются нами по электронной почте,
      посредством публикации на Сервисе&nbsp;или посредством рассылки сообщений
      в мессенджерах.
      <br />
      <br />
      <br />
      18.4. Для продвижения Сервиса и увеличения просмотров объявлений
      потенциальными Путешественниками, объявления и иной контент могут
      демонстрироваться на других сайтах, в приложениях, в электронных письмах,
      а также в онлайновых и оффлайновых рекламных объявлениях. Чтобы помочь
      Пользователям, говорящим на разных языках, объявления и иной контент могут
      быть полностью или частично переведены на другие языки. Мы не можем в
      полной мере гарантировать точность или качество подобных переводов. На
      Сервисе могут отображаться переводы, созданные сторонними компаниями.
      <br />
      <br />
      18.5. Сервис EXAYNA может содержать ссылки на сторонние сайты и ресурсы.
      Использование сторонних сайтов и ресурсов может регулироваться другими
      правилами и условиями, в том числе условиями конфиденциальности. Мы не
      несем ответственности за доступность или точность сторонних сайтов и
      ресурсов, а также за контент, продукты или услуги сторонних сайтов и
      ресурсов. Наличие ссылок на сторонние сайты и ресурсы не подразумевает
      каких-либо гарантий в отношении таких сторонних сайтов и ресурсов с нашей
      стороны.
      <br />
      <br />
      <b>Обратная связь&nbsp;</b>
      <br />
      E-mail:&nbsp;support@exayna.com&nbsp;&nbsp;
      <br />
      <br />
      <b>Правила использования Электронного подарочного сертификата (ЭПС):</b>
      <br />
      <br />
      1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Электронный подарочный
      сертификат используется на сервисах EXAYNA.
      <br />
      <br />
      2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Отправиться в путешествие с
      использованием ЭПС можно в течение 2 лет со дня его приобретения, а
      использовать его для оплаты тура необходимо за 1 год с момента
      приобретения. Если получатель не сделает этого, ЭПС становится
      недействительным и денежная сумма, потраченная на приобретение ЭПС,
      сгорает.
      <br />
      <br />
      3.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Денежные средства, уплата которых
      подтверждается ЭПС, принимаются в счет оплаты первой части суммы Заказа
      (то есть предоплаты) любого Тревел-эксперта (автора маршрута путешествия,
      гида, инструктора), разместившего предложение (объявление об авторском
      маршруте) на сервисах EXAYNA, действующее на момент предъявления
      сертификата.
      <br />
      <br />
      4.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Для погашения ЭПС и начисления
      бонусных баллов в личном кабинете путешественника необходимо
      зарегистрировать аккаунт.
      <br />
      <br />
      5.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;После создания аккаунта свяжитесь с
      нашими менеджерами через&nbsp;чат поддержки, по электронной почте support@
      exayna.com&nbsp;или напишите нам в любую из социальных сетей.
      <br />
      <br />
      6.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Если сумма номинала ЭПС меньше
      стоимости заказа, владелец ЭПС может доплатить разницу. Если же сумма ЭПС
      больше стоимости заказа, то после частичного погашения ЭПС остаток не
      сгорает, а сохраняется для будущих путешествий.
      <br />
      <br />
      7.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Количество ЭПС, используемых при
      осуществлении заказа, не ограничено.
      <br />
      <br />
      8.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;В случае, если у Пользователя
      возникли вопросы касательно приобретения, использования и погашения ЭПС.
      Он может связаться с нами любым удобным способом из указанных в
      соответствующем разделе Сайта.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          msoFareastFontFamily: '"Times New Roman"',
          msoFareastLanguage: "RU"
        }}
      >
        Приложение № 1 к Пользовательскому соглашению EXAYNA
      </span>
    </b>
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          msoFareastFontFamily: '"Times New Roman"',
          msoFareastLanguage: "RU"
        }}
      >
        Ответственность за нарушения правил Платформы
      </span>
    </b>
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      Мы уделяем большое внимание содержанию контента, который публикуется
      пользователями (Путешественниками и Тревел-экспертами) на нашем Сайте.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      Мы убедительно просим всех пользователей нашего сайта и особенно
      Тревел-экспертов внимательно ознакомиться с информацией, изложенной ниже:
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          msoFareastFontFamily: '"Times New Roman"',
          msoFareastLanguage: "RU"
        }}
      >
        Авторские права
      </span>
    </b>
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – Нельзя просто так взять фотографию или любой другой аналогичный контент
      из интернета и разместить на Сайте.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – Все права на фотографии принадлежат авторам, то есть тем, кто такие
      фотографии сделал (например, фотограф) или лицам, представляющим интересы
      авторов (различные агентства и прочие).
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – Тот факт, что кто-то разместил фото в интернете и Вы его можете скачать
      и выложить на Сайте не означает, что Ваши действия являются законными.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – Перед публикацией на нашем Сайте любых фотографий или любого иного
      контента, защищенного авторским правом, Вы обязаны получить письменное
      согласие на такое использование от автора фотографии или иного
      уполномоченного представителя автора и предоставить его нам.&nbsp;
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – Согласия не требуется только в двух случаях: 1) Вы размещаете
      фотографии, сделанные Вами лично 2) Вы берете фотографии из специальных
      интернет-ресурсов (банков фотографий), которые предоставляют т.н. открытую
      лицензию, то есть право использовать фотографии, как угодно. При этом Вы
      обязаны при размещении таких фотографий на Сайте указать источник (сайт)
      откуда эти фотографии взяты.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          msoFareastFontFamily: '"Times New Roman"',
          msoFareastLanguage: "RU"
        }}
      >
        Обмен контактами
      </span>
    </b>
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      Тревел-Экспертам запрещается указывать контакты и личные данные и контакты
      и личные данные своего представителя для оформления Заказа не на Cайте, а
      напрямую с Путешественником, в обход правил Сервиса.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      В случае нарушения данных условий Пользовательского соглашения мы
      оставляем за собой право по своему усмотрению:
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <b>
      <span
        style={{
          fontSize: "12.0pt",
          fontFamily: '"Helvetica",sans-serif',
          msoFareastFontFamily: '"Times New Roman"',
          msoFareastLanguage: "RU"
        }}
      >
        В случае совершения Тревел-Экспертом:
      </span>
    </b>
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – 1 (одного) нарушения – вынести предупреждение о блокировке и снять
      верификацию аккаунта на 7 календарных дней;
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – 2 (двух) нарушений – увеличить комиссию до 16% на 1 (один) месяц;
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – 3 (трех) нарушений – увеличить комиссию до 17% на 2 (два) месяца;
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – 4 (четырех) нарушений – увеличить комиссию до 18% на 2 (два) месяца;
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      – 5 (пяти) нарушений – заблокировать аккаунт Тревел-эксперта навсегда и
      занести Тревел-Эксперта в черный список.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      В случае нарушения любого из вышеуказанных правил, мы оставляем за собой
      право заблокирвать аккаунты любого пользователя нашего Сайта.
      
    </span>
  </p>
  <p
    className="MsoNormal"
    style={{
      msoMarginTopAlt: "auto",
      msoMarginBottomAlt: "auto",
      lineHeight: "normal",
      background: "white"
    }}
  >
    <span
      style={{
        fontSize: "12.0pt",
        fontFamily: '"Helvetica",sans-serif',
        msoFareastFontFamily: '"Times New Roman"',
        msoFareastLanguage: "RU"
      }}
    >
      Настоящее Приложение № 1 к Пользовательскому соглашению является его
      неотъемлемой частью и вступает в силу с момента публикации на сайте
      exayna.com
      
    </span>
  </p>
  <p className="MsoNormal">
    
  </p>
</>


{showButton && (
    <button
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
        className="scroll-to-top"
    >
    </button>
)}
            </div>
    );
};

export default TermsOfService;
