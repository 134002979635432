import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Button, Grid } from '@mui/material';
import { KeyboardArrowUp as ArrowUpIcon } from '@mui/icons-material';
import parse from 'html-react-parser';
import TourGallery from './TourGallery';
import './Gallery.css';
import './LoadingAnimation.css';
import './TourPage.css';

const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  });
};

const formatTelegramText = (text) => {
  if (!text) return '';
  let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  formattedText = formattedText.replace(/_(.*?)_/g, '<em>$1</em>');
  formattedText = formattedText.replace(/(?:^|\n)[•-]\s*(.+)/g, '<li>$1</li>');

  if (formattedText.includes('<li>')) {
    formattedText = `<ul>${formattedText}</ul>`;
  }

  formattedText = formattedText.replace(/\n/g, '<br />');
  return formattedText;
};

const TourPage = () => {
  const { id } = useParams();
  const [tour, setTour] = useState(null);
  const [dates, setDates] = useState([]);
  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useEffect(() => {
    const fetchTour = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tours/${id}`);
        const data = await response.json();
        setTour(data);
      } catch (error) {
        console.error('Ошибка загрузки данных тура:', error);
      }
    };

    const fetchDates = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/api/tours`);
        const allDates = await response.json();
        const filteredDates = allDates.find(t => t.turId === Number(id))?.dates || [];
        
        setDates(filteredDates);
      } catch (error) {
        console.error('Ошибка загрузки дат:', error);
      }
    };

    fetchTour();
    fetchDates();

    const handleScroll = () => {
      setShowScrollToTop(window.scrollY > 200);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [id]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  if (!tour) {
    return (
      <div className="loading-container">
        <div className="double-ring"></div>
      </div>
    );
  }

  const images = tour.photoGallery || [];

  return (
    <Container sx={{ paddingTop: 4, paddingBottom: 4 }}>
      <TourGallery images={images} />

      <div className="tour-description">
        <Typography variant="h4" gutterBottom className="tour-title">{tour.name}</Typography>
        <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>Стоимость: {tour.cost} ₽</Typography>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          {tour.region}, {tour.cityStart}
        </Typography>

        <div className="tour-dates compact">
          <Typography variant="h6" gutterBottom>Даты проведения:</Typography>
          <Grid container spacing={1}>
            {dates.length > 0 ? (
              dates.map((date, index) => (
                <Grid item xs={6} sm={4} key={index} className="compact-date-item">
                  <Typography variant="body2" component="div">
                    <span className="date-range">
                      {formatDate(date.dateStart)} – {formatDate(date.dateFinish)}
                    </span>
                    <span className="free-seats">Мест: {date.freeMest}</span>
                  </Typography>
                </Grid>
              ))
            ) : (
              <Typography variant="body1" color="text.secondary">Нет доступных дат</Typography>
            )}
          </Grid>
        </div>

        <Grid container spacing={4} sx={{ mt: 4 }}>
          <Grid item xs={12} md={6} className="tour-info-box">
            <Typography variant="h6" gutterBottom>Условия проживания:</Typography>
            <div>{parse(formatTelegramText(tour.livingConditions))}</div>
          </Grid>
          <Grid item xs={12} md={6} className="tour-info-box">
            <Typography variant="h6" gutterBottom>Что включено в стоимость:</Typography>
            <div>{parse(formatTelegramText(tour.inCost))}</div>
          </Grid>
        </Grid>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Что взять с собой:</Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
          {parse(formatTelegramText(tour.importantInform))}
        </Typography>

        <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>Описание тура:</Typography>
        <Typography variant="body1" gutterBottom className="tour-additional-info">
          {parse(formatTelegramText(tour.bigDescriptions))}
        </Typography>
      </div>

      {/* Кнопка возврата наверх */}
      {showScrollToTop && (
        <Button
          onClick={scrollToTop}
          className="scroll-to-top-button"
          sx={{
            position: 'fixed',
            bottom: '80px',
            right: '16px',
            width: '50px',
            height: '50px',
            minWidth: '0',
            minHeight: '0',
            borderRadius: '50%',
            padding: '0',
            backgroundColor: '#FF9800',
            color: 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
            '&:hover': {
              backgroundColor: '#FF5722'
            }
          }}
        >
          <ArrowUpIcon />
        </Button>
      )}

<Button
  href={`https://t.me/ExaynaBot?start=${tour.turId}`}
  target="_blank"
  rel="noopener"
  onClick={(e) => {
    e.preventDefault(); // Предотвращаем стандартное поведение
    window.open(`https://t.me/ExaynaBot?start=${tour.turId}`, '_blank', 'noopener');

    // Проверяем, работает ли сайт в Telegram WebApp, прежде чем вызывать close()
    if (window.Telegram && window.Telegram.WebApp && typeof window.Telegram.WebApp.close === 'function') {
      window.Telegram.WebApp.close();
    }
  }}
  variant="contained"
  sx={{
    position: 'fixed',
    bottom: '20px',
    right: '16px',
    backgroundColor: '#FF9800',
    color: 'white',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#FF5722',
    },
  }}
>
  Забронировать в телеграм
</Button>

    </Container>
  );
};

export default TourPage;
